// Import HTTP client
import OrgServiceClient from '@/services/OrgServiceClient'

/** mutation types */
// eslint-disable-line
const set_org = 'set_org'

/** state */
const state = {
  org: null,
}

/** getters */
const getters = {
  org: (state) => state.org,
}

/** mutations */
const mutations = {
  [set_org](state, data) {
    state.org = data
  },
}

/** actions */
const actions = {
  getOrg: ({ commit }) => {
    return new OrgServiceClient().getOrg().then((resp) => {
      commit(set_org, resp.data)
      Promise.resolve(resp.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
