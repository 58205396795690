import EdgeServiceClient from '@/services/EdgeServiceClient'

/** mutation types */
export const set_apps = 'set_apps'
export const set_app = 'set_app'
export const set_current_app = 'set_current_app'
export const set_current_app_logs = 'set_current_app_logs'
export const delete_app = 'delete_app'

/** state */
const getDefaultState = () => {
  return {
    apps: null,
    currentApp: {},
    currentAppLogs: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  apps: (state) => state.apps,
  currentApp: (state) => state.currentApp,
  currentAppLogs: (state) => state.currentAppLogs,
}

/** mutations */
const mutations = {
  [set_apps](state, data) {
    state.apps = data
  },
  [set_app](state, data) {
    state.apps = [...data, ...state.apps]
  },
  [set_current_app](state, data) {
    state.currentApp = data
  },
  [set_current_app_logs](state, data) {
    state.currentAppLogs = data
  },
  [delete_app](state, id) {
    const index = state.apps.findIndex((app) => app.id === id)
    state.apps.splice(index, 1)
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

/** actions */
const actions = {
  clearCurrentApp: ({ commit }) => {
    commit(set_current_app, {})
  },
  clearCurrentAppLogs: ({ commit }) => {
    commit(set_current_app_logs, null)
  },
  getApps: ({ commit }) => {
    return new EdgeServiceClient().getApps().then((response) => {
      commit(set_apps, response.data)
      return Promise.resolve(response.data)
    })
  },
  createApp: ({ commit }, payload) => {
    return new EdgeServiceClient().createApp(payload).then((response) => {
      commit(set_app, [response.data])
      return Promise.resolve(response.data)
    })
  },
  getAppDetails: ({ commit }, id) => {
    return new EdgeServiceClient().getApp(id).then((response) => {
      commit(set_current_app, response.data)
      return Promise.resolve(response.data)
    })
  },
  getAppLogs: ({ commit }, id) => {
    return new EdgeServiceClient().getAppLogs(id).then((response) => {
      // get first 100
      const first100Logs = response.data.splice(0, 100)
      commit(set_current_app_logs, first100Logs)
      return Promise.resolve(response.data)
    })
  },
  resetAppState({ commit }) {
    commit('resetState')
  },
  editApp: ({ commit }, args) => {
    return new EdgeServiceClient().editApp(args.payload, args.id)
  },
  deleteApp: ({ commit }, id) => {
    return new EdgeServiceClient()
      .deleteApp(id)
      .then((response) => {
        commit(delete_app, id)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
