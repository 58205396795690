import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const guardProtectedRoutes = (_, __, next) => {
  let path
  if (!localStorage.getItem('jwt')) {
    /* go to login */
    path = { path: '/login' }
  }
  next(path)
}
const guardAuthRoutes = (_, __, next) => {
  let path
  if (localStorage.getItem('jwt')) {
    /* go to app */
    path = { path: '/dashboard' }
  }
  next(path)
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/auth/AuthLayout.vue'),
    beforeEnter: guardAuthRoutes,
    children: [
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
      },
      {
        path: '/',
        component: () => import('@/views/auth/Login.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import('@/views/auth/ForgotPassword.vue'),
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import('@/views/auth/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/AuthLayout.vue'),
    children: [
      {
        path: 'callback',
        name: 'callback',
        component: () => import('@/views/auth/Callback.vue'),
        props: (route) => ({ token: route.query.token }),
      },
    ],
  },
  {
    path: '/tx/:method',
    name: 'confirmpayment',
    component: () => import('@/views/billing/tx/ConfirmPayment.vue'),
    props: (route) => ({
      method: route.params.method,
      trxref: route.query.trxref,
      reference: route.query.reference,
    }),
  },
  /** app, guarded routes */
  {
    path: '/',
    name: 'App',
    component: () => import('@/views/AppLayout.vue'),
    beforeEnter: guardProtectedRoutes,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      /** apps */
      {
        path: '/apps/create',
        name: 'createApp',
        component: () => import('@/views/apps/CreateApp.vue'),
        meta: {
          title: 'Apps',
          hasBackButton: true,
        },
      },
      {
        path: 'apps/create/add-endpoint',
        name: 'addEndpoint',
        component: () => import('@/views/apps/CreateApp.vue'),
        meta: {
          showModal: true,
          title: 'Create App',
          hasBackButton: true,
        },
      },
      {
        path: 'apps/create/add-project',
        name: 'addAppProject',
        component: () => import('@/views/apps/CreateApp.vue'),
        meta: {
          showCreateProjectModal: true,
          title: 'Create App',
          hasBackButton: true,
        },
      },
      {
        path: '/apps/:id/edit',
        name: 'editApp',
        component: () => import('@/views/apps/EditApp.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          title: 'Edit App',
          hasBackButton: true,
        },
      },
      {
        path: '/apps/:id/edit/add-endpoint',
        name: 'addMoreEndpoint',
        component: () => import('@/views/apps/EditApp.vue'),
        meta: {
          showModal: true,
          title: 'Edit App',
          hasBackButton: true,
        },
      },
      {
        path: '/apps/:id',
        component: () => import('@/views/apps/App.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          hasBackButton: true,
        },
        children: [
          {
            path: 'logs',
            name: 'appLogs',
            component: () => import('@/views/apps/AppLogs.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'analytics',
            name: 'appAnalytics',
            component: () => import('@/views/apps/AppAnalytics.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'details',
            name: 'appDetails',
            component: () => import('@/views/apps/AppDetails.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'details',
            name: 'viewEndpoint',
            component: () => import('@/views/apps/AppDetails.vue'),
            meta: {
              showViewModal: true,
              hasBackButton: true,
            },
          },
          {
            path: 'details',
            name: 'viewEnvironment',
            component: () => import('@/views/apps/AppDetails.vue'),
            meta: {
              showViewModal: true,
              hasBackButton: true,
            },
          },
          {
            path: '/apps/:id/details/add-endpoint',
            name: 'addEndpointEdit',
            component: () => import('@/views/apps/AppDetails.vue'),
            meta: {
              showModal: true,
              hasBackButton: true,
            },
          },
          {
            path: '',
            redirect: { name: 'appAnalytics' },
          },
        ],
      },
      {
        path: '/apps',
        name: 'apps',
        component: () => import('@/views/apps/Apps.vue'),
        meta: {
          title: 'Apps',
          hasActionButton: true,
          actionButton: {
            title: 'Create App',
            linkName: 'createApp',
            icon: 'mdi-plus',
          },
        },
      },
      /** edge */
      {
        path: '/proxies/create',
        name: 'createEdge',
        component: () => import('@/views/edge/CreateEdge.vue'),
        meta: {
          title: 'Create Edge Proxy',
          hasBackButton: true,
        },
      },
      {
        path: 'proxies/create/add-endpoint',
        name: 'addEdgeEndpoint',
        component: () => import('@/views/edge/CreateEdge.vue'),
        meta: {
          showModal: true,
          title: 'Create Edge Proxy',
          hasBackButton: true,
        },
      },
      {
        path: 'proxies/:id/edit',
        name: 'editEdge',
        component: () => import('@/views/edge/EditEdge.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          title: 'Edit Edge Proxy',
          hasBackButton: true,
        },
      },
      {
        path: 'proxies/:id/edit/add-endpoint',
        name: 'addMoreEdgeEndpoint',
        component: () => import('@/views/edge/EditEdge.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          showModal: true,
          title: 'Edit Edge Proxy',
          hasBackButton: true,
        },
      },
      {
        path: '/proxies/:id',
        component: () => import('@/views/edge/Edge.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          hasBackButton: true,
        },
        children: [
          {
            path: 'logs',
            name: 'edgeLogs',
            component: () => import('@/views/edge/EdgeLogs.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'analytics',
            name: 'edgeAnalytics',
            component: () => import('@/views/edge/EdgeAnalytics.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'details',
            name: 'edgeDetails',
            component: () => import('@/views/edge/EdgeDetails.vue'),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: 'details/add-endpoint',
            name: 'edgeEndpointEdit',
            component: () => import('@/views/edge/EdgeDetails.vue'),
            meta: {
              showModal: true,
              hasBackButton: true,
            },
          },
          {
            path: 'details',
            name: 'viewEdgeEndpoint',
            component: () => import('@/views/edge/EdgeDetails.vue'),
            meta: {
              showViewModal: true,
              hasBackButton: true,
            },
          },
          {
            path: '',
            redirect: { name: 'edgeAnalytics' },
          },
        ],
      },
      {
        path: '/proxies',
        name: 'proxies',
        component: () => import('@/views/edge/Edges.vue'),
        meta: {
          title: 'Proxies',
          hasActionButton: true,
          actionButton: {
            title: 'Create Proxy',
            linkName: 'createEdge',
            icon: 'mdi-plus',
          },
        },
      },
      {
        path: '/billing',
        name: 'billing',
        component: () => import('@/views/billing/Billing.vue'),
        meta: {
          title: 'Billing',
          hasActionButton: true,
          actionButton: {
            title: 'Fund Wallet',
            linkName: 'fundWallet',
            icon: 'mdi-plus',
          },
        },
      },
      {
        path: '/billing/fund',
        name: 'fundWallet',
        component: () => import('@/views/billing/Billing.vue'),
        meta: {
          title: 'Billing',
          hasActionButton: true,
          actionButton: {
            title: 'Fund Wallet',
            linkName: 'fundWallet',
            icon: 'mdi-plus',
          },
          showModal: true,
        },
      },
      /** settings */
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/settings/Settings.vue'),
        meta: {
          showModal: false,
          title: 'Settings',
          hasBackButton: true,
        },
        children: [
          {
            path: 'tokens',
            name: 'tokens',
            component: () => import('@/views/settings/developer/Tokens.vue'),
            meta: {
              showModal: false,
              title: 'Settings',
              hasBackButton: true,
              hasActionButton: true,
              actionButton: {
                title: 'Create New Token',
                linkName: 'createtoken',
                icon: 'mdi-plus',
              },
            },
          },
          {
            path: 'tokens/create',
            name: 'createtoken',
            component: () => import('@/views/settings/developer/Tokens.vue'),
            meta: {
              showModal: true,
              title: 'Settings',
              hasBackButton: true,
            },
          },
          {
            path: '',
            redirect: { name: 'tokens' },
          },
        ],
      },
      {
        path: '/*',
        component: () => import('@/views/apps/Apps.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
