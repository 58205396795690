/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'

export default class AppServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  getApps() {
    return this.client.get('/v1/apps')
  }
  createApp(payload) {
    return this.client.post('/v1/apps', payload)
  }
  getAppDetails(appID) {
    return this.client.get(`/v1/apps/${appID}`)
  }
  getAppLogs(appID) {
    return this.client.get(`/v1/apps/${appID}/traffic/logs`)
  }
  deleteApp(appID) {
    return this.client.delete(`/v1/apps/${appID}`)
  }
  editApp(payload, appID) {
    return this.client.put(`/v1/apps/${appID}`, payload)
  }
  getProjects() {
    return this.client.get('/v1/namespaces')
  }
  createProject(payload) {
    return this.client.post('/v1/namespaces', payload)
  }
}
