/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'
const DEFAULT_MODE = 'proxies'

export default class EdgeServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  getApps(mode = DEFAULT_MODE) {
    return this.client.get(`/v1/${mode}`)
  }
  createApp(mode = DEFAULT_MODE, payload) {
    return this.client.post(`/v1/${mode}`, payload)
  }
  getApp(mode = DEFAULT_MODE, id) {
    return this.client.get(`/v1/${mode}/${id}`)
  }
  getAppLogs(mode = DEFAULT_MODE, id) {
    return this.client.get(`/v1/${mode}/${id}/traffic/logs`)
  }
  editApp(mode = DEFAULT_MODE, payload, id) {
    return this.client.put(`/v1/${mode}/${id}`, payload)
  }
  deleteApp(mode = DEFAULT_MODE, id) {
    return this.client.delete(`/v1/${mode}/${id}`)
  }
}
