/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'

export default class BillingServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  getAnalytics() {
    return this.client.get('/v1/analytics')
  }

  getMetrics(id, params) {
    return this.client.get(`/v1/metrics/${id}`, { params })
  }

  getTopMetrics(id, params) {
    return this.client.get(`/v1/metrics/${id}/top`, { params })
  }

  getPhoneInstalledAppsAnalytics() {
    return this.client.get(`/v1/analytics/phone/installed_apps`)
  }

  getPhoneModelsAnalytics() {
    return this.client.get(`/v1/analytics/phone/models`)
  }

  getPhoneBrandsAnalytics() {
    return this.client.get(`/v1/analytics/phone/brands`)
  }
}
