// Import HTTP client
import AuthServiceClient from '@/services/AuthServiceClient'
import BaseIOClient from '../services/core/BaseIOClient'
import { loginIOKey, registerIOKey } from '../utils/ioConstants'

/** mutation types */
// eslint-disable-line
const set_user = 'set_user'

/** state */
const state = {
  user: {},
}

/** getters */
const getters = {
  user: (state) => state.user,
}

/** mutations */
const mutations = {
  [set_user](state, data) {
    state.user = data
  },
}

/** actions */
const actions = {
  register: ({ commit }, payload) => {
    return new AuthServiceClient().register(payload)
  },
  forgotPassword: ({ commit }, payload) => {
    return new AuthServiceClient().forgotPassword(payload)
  },
  resetPassword: ({ commit }, payload) => {
    return new AuthServiceClient().resetPassword(payload)
  },
  login: ({ commit }, payload) => {
    return new AuthServiceClient().login(payload).then((response) => {
      localStorage.setItem('jwt', response.data.jwt)
      const user = response.data.account
      commit(set_user, user)
    })
  },
  initLogin: ({ commit }, payload) => {
    return new AuthServiceClient().initLogin(payload).then((response) => {
      return Promise.resolve(response.data)
    })
  },
  initLoginCompleteWebsocket: () => {
    return new BaseIOClient({
      path: '/v1/auth/login/complete',
      key: loginIOKey,
    })
  },
  initRegister: ({ commit }, payload) => {
    return new AuthServiceClient().initRegister(payload).then((response) => {
      return Promise.resolve(response.data)
    })
  },
  initRegisterCompleteWebsocket: () => {
    return new BaseIOClient({
      path: '/v1/auth/register/complete',
      key: registerIOKey,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
