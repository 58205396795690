// Import HTTP client
import BillingServiceClient from '@/services/BillingServiceClient'

/** mutation types */
// eslint-disable-line
const set_wallet = 'set_wallet'
const set_tx = 'set_tx'

/** state */
const state = {
  wallet: { balance: 0, lifetime_value: 0 },
  tx: [],
}

/** getters */
const getters = {
  walletBalance: (state) => parseInt(state.wallet.balance) / 100,
  lifetimeWallet: (state) => parseInt(state.wallet.lifetime_value) / 100,
  tx: (state) => state.tx,
}

/** mutations */
const mutations = {
  [set_wallet](state, data) {
    state.wallet = data
  },
  [set_tx](state, data) {
    state.tx = data
  },
}

/** actions */
const actions = {
  fund: (_, payload) => {
    return new BillingServiceClient().fund(payload)
  },
  getWalletBalance: ({ commit }) => {
    return new BillingServiceClient().getWalletBalance().then((resp) => {
      commit(set_wallet, resp.data)
      Promise.resolve(resp.data)
    })
  },
  getTx: ({ commit }) => {
    return new BillingServiceClient().getTx().then((resp) => {
      commit(set_tx, resp.data)
      Promise.resolve(resp.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
