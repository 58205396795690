/* jshint sub:true */
import BaseClient from '@/services/core/BaseClient'

export default class BaseClientAuth extends BaseClient {
  constructor(options) {
    super(options)
    const token = localStorage.getItem('jwt')
    this.client.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}
