/* eslint-disable no-new */
import axios from 'axios'

import store from '@/store'
import RequestInterceptor from '@/services/interceptor/Request'
import ResponseInterceptor from '@/services/interceptor/Response'

const domain = process.env.VUE_APP_API_DOMAIN

export default class BaseClient {
  constructor(options = {}) {
    this.client = axios.create({
      baseURL: domain,
      ...options,
    })
    new RequestInterceptor(this.client, store.commit)
    new ResponseInterceptor(this.client, store.commit)
  }
}
