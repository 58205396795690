<template>
  <v-snackbar
    :color="currentMessage.type"
    v-model="currentMessage"
    v-if="currentMessage"
  >
    <div>
      <b>{{ currentMessage.title }}</b>
      <p>{{ currentMessage.description }}</p>
    </div>
    <v-btn text @click="clearMessage"> Close </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AppSnackbar',
  computed: {
    currentMessage: {
      get: mapGetters({
        currentMessage: 'ui/currentMessage',
      }).currentMessage,
      set: function () {
        this.clearMessage()
      },
    },
  },
  methods: {
    ...mapActions({
      clearMessage: 'ui/clearMessage',
    }),
  },
}
</script>
