<template>
  <v-app>
    <router-view />
    <app-snackbar />
    <section id="custom_modal__portal" />
  </v-app>
</template>

<script>
import AppSnackbar from '@/components/cards/AppSnackbar.vue'
export default {
  name: 'App',
  components: {
    'app-snackbar': AppSnackbar,
  },
}
</script>
<style lang="scss">
@import './assets/styles/main.scss';
</style>
