/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'

export default class OrgServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  getToken(id) {
    return this.client.get(`/v1/authtokens/${id}`)
  }

  getTokens() {
    return this.client.get(`/v1/authtokens`)
  }

  deleteToken(id) {
    return this.client.delete(`/v1/authtokens/${id}`)
  }

  createToken(formData) {
    return this.client.post(`/v1/authtokens`, formData)
  }

  editToken(payload) {
    return this.client.put(`/v1/authtokens/${payload.id}`, payload)
  }
}
