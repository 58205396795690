import { set_error } from '@/store/ui.store'

export default class ResponseInterceptor {
  constructor(axiosInstance, commit) {
    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data.success === false) {
          handleError(commit, response.data.msg, response.data.errors[0])
          return Promise.reject(new Error())
        }

        return Promise.resolve(response)
      },
      (error) => {
        let message = null
        try {
          message = error.response?.data?.internal
        } catch (error) {
          message = 'Oops! Something went wrong, please try again.'
        }
        handleError(commit, 'An error occurred', message)

        return Promise.reject(error)
      }
    )
  }
}

const handleError = (commit, message, error) => {
  commit(
    `ui/${set_error}`,
    {
      title: message,
      description: error,
    },
    { root: true }
  )
}
