import jwt_decode from 'jwt-decode'
import { set_error } from '@/store/ui.store'

export default class RequestInterceptor {
  constructor(axiosInstance, commit) {
    axiosInstance.interceptors.request.use((request) => {
      if (!navigator.onLine) {
        commit(
          `ui/${set_error}`,
          {
            title: 'No Internet connection',
            description:
              'Oops! Unable to complete request, please check your internet connectivity and try again.',
          },
          { root: true }
        )
      }

      const jwt = localStorage.getItem('jwt')

      if (jwt) {
        const { exp: token_expiry_time } = jwt_decode(jwt)

        if (this.is_session_expired(token_expiry_time)) {
          commit(
            `ui/${set_error}`,
            {
              title: 'Session expired',
              description:
                'Oops! Your session has expired, please proceed to relogin',
            },
            { root: true }
          )

          setTimeout(() => {
            localStorage.clear('jwt')
            location.href = '/login'
          }, 500)
        }
      }

      return request
    })
  }

  is_session_expired(token_expiry_time) {
    return new Date().getTime() >= token_expiry_time * 1000
  }
}
