// Import HTTP client
import AdminServiceClient from '@/services/AdminServiceClient'

/** mutation types */
// eslint-disable-line
const set_tokens = 'set_tokens'
const set_token = 'set_token'
const delete_token = 'delete_token'

/** state */
const state = {
  tokens: [],
}

/** getters */
const getters = {
  tokens: (state) => state.tokens,
}

/** mutations */
const mutations = {
  [set_tokens](state, data) {
    state.tokens = data
  },
  [set_token](state, data) {
    state.tokens = [data, ...state.tokens]
  },
  [delete_token](state, id) {
    state.tokens = state.tokens.filter((vpn) => vpn.id !== id)
  },
}

/** actions */
const actions = {
  getTokens: ({ commit }) => {
    return new AdminServiceClient().getTokens().then((resp) => {
      commit(set_tokens, resp.data)
      Promise.resolve(resp.data)
    })
  },
  createToken: ({ commit }, formData) => {
    return new AdminServiceClient().createToken(formData).then((resp) => {
      commit(set_token, resp.data)
      Promise.resolve(resp.data)
      return resp.data
    })
  },
  deleteToken: ({ commit }, id) => {
    return new AdminServiceClient().deleteToken(id).then((resp) => {
      commit(delete_token, id)
      Promise.resolve(resp.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
