/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'

export default class BillingServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  fund(payload) {
    return this.client.post('/v1/wallets', payload)
  }

  getWalletBalance() {
    return this.client.get('/v1/wallets')
  }

  getTx() {
    return this.client.get('/v1/wallets/transactions')
  }
}
