// Import HTTP client
import AnalyticsServiceClient from '@/services/AnalyticsServiceClient'

/** mutation types */
// eslint-disable-line
const set_tm_metrics = 'set_tm_metrics'
const set_analytics = 'set_analytics'
const clear_metrics = 'clear_metrics'

const defaultTimeSeries = {
  all: [{ Metric: '', TS: [] }],
  telco: [{ Metric: '', TS: [] }],
  fqdn: [{ Metric: '', TS: [] }],
  toptelco: null,
  topfqdn: null,
  topuser: null,
  installapps: null,
  phonemodels: null,
  phonebrands: null,
}
/** state */
const state = {
  timeSeriesMetrics: defaultTimeSeries,
  generic: {
    fqdns_data_usage: null,
    telcos_data_usage: null,
    hourly_data_usage: null,
    msisdn_data_usage: null,
  },
}

/** getters */
const getters = {
  timeSeriesMetrics: (state) => state.timeSeriesMetrics,
  timeSeriesMetricsByAll: (state) => state.timeSeriesMetrics.all,
  timeSeriesMetricsByTelco: (state) => state.timeSeriesMetrics.telco,
  timeSeriesMetricsByFQDN: (state) => state.timeSeriesMetrics.fqdn,
  metricsByTopTelco: (state) => state.timeSeriesMetrics.toptelco,
  metricsByTopFQDN: (state) => state.timeSeriesMetrics.topfqdn,
  metricsByTopUser: (state) => state.timeSeriesMetrics.topuser,
  phoneInstalledApps: (state) => state.timeSeriesMetrics.installapps,
  phoneModels: (state) => state.timeSeriesMetrics.phonemodels,
  phoneBrands: (state) => state.timeSeriesMetrics.phonebrands,
  metricsByTelco: (state) => state.generic.telcos_data_usage,
  metricsByFQDN: (state) => state.generic.fqdns_data_usage,
  metricsByMSISDN: (state) => state.generic.msisdn_data_usage,
  metricsByHourlyDataUsage: (state) => state.generic.hourly_data_usage,
}

/** mutations */
const mutations = {
  [set_tm_metrics](state, { key, data }) {
    state.timeSeriesMetrics[key] = data
  },
  [set_analytics](state, data) {
    state.generic = data
  },
  [clear_metrics](state) {
    state.timeSeriesMetrics = defaultTimeSeries
  },
}

/** actions */
const actions = {
  getAnalytics: ({ commit }) => {
    return new AnalyticsServiceClient().getAnalytics().then((resp) => {
      commit(set_analytics, resp.data)
      Promise.resolve(resp.data)
    })
  },
  getTimeSeriesMetrics: ({ commit }, { id, key, params }) => {
    return new AnalyticsServiceClient().getMetrics(id, params).then((resp) => {
      commit(set_tm_metrics, { key, data: resp.data })
      Promise.resolve(resp.data)
    })
  },
  getTopMetrics: ({ commit }, { id, key, params }) => {
    return new AnalyticsServiceClient()
      .getTopMetrics(id, params)
      .then((resp) => {
        commit(set_tm_metrics, { key, data: resp.data })
        Promise.resolve(resp.data)
      })
  },
  getPhoneInstalledApps: ({ commit }) => {
    return new AnalyticsServiceClient()
      .getPhoneInstalledAppsAnalytics()
      .then((resp) => {
        commit(set_tm_metrics, { key: 'installapps', data: resp.data })
        Promise.resolve(resp.data)
      })
  },
  getPhoneModels: ({ commit }) => {
    return new AnalyticsServiceClient()
      .getPhoneModelsAnalytics()
      .then((resp) => {
        commit(set_tm_metrics, { key: 'phonemodels', data: resp.data })
        Promise.resolve(resp.data)
      })
  },
  getPhoneBrands: ({ commit }) => {
    return new AnalyticsServiceClient()
      .getPhoneBrandsAnalytics()
      .then((resp) => {
        commit(set_tm_metrics, { key: 'phonebrands', data: resp.data })
        Promise.resolve(resp.data)
      })
  },
  clear: ({ commit }) => {
    commit(clear_metrics)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
