import AppServiceClient from '@/services/AppServiceClient'
import BaseIOClient from '../services/core/BaseIOClient'
import { appLogsIOKey } from '../utils/ioConstants'

/** mutation types */
export const set_projects = 'set_projects'
export const set_project = 'set_project'
export const set_apps = 'set_apps'
export const set_app = 'set_app'
export const set_current_app = 'set_current_app'
export const set_current_app_env = 'set_current_app_env'
export const set_current_app_logs = 'set_current_app_logs'
export const delete_new_app_endpoint = 'delete_new_app_endpoint'
export const delete_app = 'delete_app'

/** state */
const getDefaultState = () => {
  return {
    apps: null,
    projects: null,
    currentApp: {},
    currentAppEnv: null,
    currentAppLogs: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  projects: (state) => state.projects,
  apps: (state) => state.apps,
  currentApp: (state) => state.currentApp,
  currentAppEnv: (state) => state.currentAppEnv,
  currentAppLogs: (state) => state.currentAppLogs,
}

/** mutations */
const mutations = {
  [set_projects](state, data) {
    state.projects = data
  },
  [set_project](state, data) {
    state.projects = [...data, ...state.projects]
  },
  [set_apps](state, data) {
    state.apps = data
  },
  [set_app](state, data) {
    state.apps = [...data, ...state.apps]
  },
  [set_current_app](state, data) {
    state.currentApp = data
  },
  [set_current_app_env](state, data) {
    state.currentAppEnv = data
  },
  [set_current_app_logs](state, data) {
    state.currentAppLogs = data
  },
  [delete_app](state, id) {
    const index = state.apps.findIndex((app) => app.id === id)
    state.apps.splice(index, 1)
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

/** actions */
const actions = {
  clearCurrentApp: ({ commit }) => {
    commit(set_current_app, {})
  },
  clearCurrentAppLogs: ({ commit }) => {
    commit(set_current_app_logs, null)
  },
  getProjects: ({ commit }) => {
    return new AppServiceClient().getProjects().then((response) => {
      commit(set_projects, response.data)
      return Promise.resolve(response.data)
    })
  },
  createProject: ({ commit }, payload) => {
    return new AppServiceClient().createProject(payload).then((response) => {
      commit(set_project, [response.data])
      return Promise.resolve(response.data)
    })
  },
  getApps: ({ commit }) => {
    return new AppServiceClient().getApps().then((response) => {
      commit(set_apps, response.data)
      return Promise.resolve(response.data)
    })
  },
  createApp: ({ commit }, payload) => {
    return new AppServiceClient().createApp(payload).then((response) => {
      commit(set_app, [response.data])
      return Promise.resolve(response.data)
    })
  },
  resetAppState({ commit }) {
    commit('resetState')
  },
  getAppDetails: ({ commit }, id) => {
    return new AppServiceClient().getAppDetails(id).then((response) => {
      commit(set_current_app, response.data)
      return Promise.resolve(response.data)
    })
  },
  getAppLogs: ({ commit }, id) => {
    return new AppServiceClient().getAppLogs(id).then((response) => {
      commit(set_current_app_logs, response.data)
      return Promise.resolve(response.data)
    })
  },
  getAppEnv: ({ commit }, data) => {
    commit(set_current_app_env, data)
  },
  editApp: ({ commit }, args) => {
    return new AppServiceClient().editApp(args.payload, args.id)
  },
  deleteApp: ({ commit }, id) => {
    return new AppServiceClient()
      .deleteApp(id)
      .then((response) => {
        commit(delete_app, id)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  initGetAppLogWebsocket: (_, { app_id, environment }) => {
    return new BaseIOClient({
      path: `/v1/apps/${app_id}/environments/${environment}/logs`,
      key: appLogsIOKey,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
