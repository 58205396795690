import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.store'
import ui from './ui.store'
import org from './org.store'
import app from './app.store'
import edge from './edge.store'
import billing from './billing.store'
import analytics from './analytics.store'
import admin from './admin.store'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    ui,
    auth,
    org,
    app,
    edge,
    billing,
    analytics,
    admin,
  },
  plugins: [vuexLocal.plugin],
})

export default store
