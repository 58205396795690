import Vue from 'vue'

Vue.component('s-card', () => import('@/components/ui/SCard'))
Vue.component('s-btn', () => import('@/components/ui/SButton'))
Vue.component('s-text-field', () => import('@/components/ui/STextField'))
Vue.component('s-text-area', () => import('@/components/ui/STextArea'))
Vue.component('s-icon', () => import('@/components/ui/SIcon'))
Vue.component('s-text', () => import('@/components/ui/SText'))
Vue.component('s-link', () => import('@/components/ui/SLink'))
Vue.component('s-navigation-drawer', () =>
  import('@/components/ui/SNavigationDrawer')
)
Vue.component('s-dialog', () => import('@/components/ui/SDialog'))
Vue.component('s-select', () => import('@/components/ui/SSelect'))
Vue.component('s-tabs', () => import('@/components/ui/STabs'))
