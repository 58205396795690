/* eslint-disable */
import BaseClient from '@/services/core/BaseClient'

export default class AuthServiceClient extends BaseClient {
  constructor(options) {
    super(options)
  }

  register(payload) {
    return this.client.post('/v1/auth/register', payload)
  }
  login(payload) {
    return this.client.post('/v1/auth/login', payload)
  }
  forgotPassword(payload) {
    return this.client.post('/v1/auth/forgot', payload)
  }
  resetPassword(payload) {
    return this.client.post('/v1/auth/reset', payload)
  }
  initLogin(payload) {
    return this.client.post('/v1/auth/login/initiate', payload)
  }
  initRegister(payload) {
    return this.client.post('/v1/auth/register/initiate', payload)
  }
}
