import Vue from 'vue'

/** EventBus works to pass signals simply between componenets to aid decoupling
 * SimpleEventBus is a transport channel for all HTTP interceptor signals which need to be monitored
 * in other components to profive active feedback to http events
 *
 * Sending a signal through the bus
 * SimpleEventBus.$emit('label', data)
 *
 * Add a listener to the bus
 * SimpleEventBus.$on('label', data)
 *
 * Remove a listener from the bus
 * SimpleEventBus.$off('label')
 */
export const SimpleEventBus = new Vue()
