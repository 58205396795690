/* eslint-disable */
import BaseClientAuth from '@/services/core/BaseClientAuth'

export default class OrgServiceClient extends BaseClientAuth {
  constructor(options) {
    super(options)
  }

  getOrg() {
    return this.client.get('/v1/org')
  }
}
