/* eslint-disable no-new */

import { SimpleEventBus } from './EventBus'

const domain = process.env.VUE_APP_WEBSOCKET_DOMAIN

export default class BaseIOClient {
  constructor(options = {}) {
    this.socket = new WebSocket(`${domain}${options.path}`)

    // key is use to associate messages with clients
    this.socketKey = options.key

    // track when new messages arrive
    this.socket.onmessage = (event) => {
      let responseData = null
      try {
        responseData = JSON.parse(event.data)
      } catch (error) {
        console.error('Unable to parse data')
        responseData = event.data
      }
      SimpleEventBus.$emit(this.socketKey, responseData)
    }

    // track when io opens
    this.socket.onopen = () => {
      SimpleEventBus.$emit(this.socketKey, 'opened')
      console.info('Successfully connected to the echo websocket server...')
    }

    // track when io closes
    this.socket.onclose = () => {
      console.info('Successfully closed websocket...')
      SimpleEventBus.$emit(this.socketKey, 'closed')
    }

    this.stopPoll()
    this.messages = []
    this.headIndex = 0
    this.failCount = 0
  }

  send() {
    // return if socket not ready
    if (this.socket.readyState !== WebSocket.OPEN) return
    // stop when max fail count reached
    if (this.failCount >= 10) {
      this.stopPoll()
      return
    }
    // exit if queue is empty
    if (this.messages.length === 0) {
      this.stopPoll()
      return
    }
    // get item head index
    const payload = this.messages[this.headIndex]
    if (payload === undefined) {
      this.stopPoll()
      return
    }

    this.headIndex += 1
    this.socket.send(payload)
  }

  queue(payload) {
    // stack-like queue FIFO
    // Always add new items to end
    this.messages.push(payload)

    // return if queue already being burnt down
    if (this.timex) return

    // start queue burn down
    this.timex = setInterval(() => {
      this.send()
    }, 1000)
  }

  close() {
    if (this.socket.CLOSED || this.socket.CLOSING) return
    this.socket.close()
  }

  stopPoll() {
    // stop queue burn down
    // note: some messages might be stuck in queue
    // will be sent once queue is re-started
    clearInterval(this.timex)
  }
}
